import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

import { Frame, motion } from "framer"

export function ChangeTextColorWithHover() {
    return (
        <Frame
            as={motion.div}
            size={300}
            background={null}
            center
            initial={{ color: "black" }}
            whileHover={{ color: "orange" }}
        >
            Prejdite cez mňa!
        </Frame>
    )
}
